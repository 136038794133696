/* customStyles.css */
.search-input-container {
  position: relative;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
}
.pac-container {
  z-index: 111111 !important; /* Bootstrap modals have z-index 1050, so set higher */
}

.highlight {
  background-color: yellow;
  color: black;
}
.search-input-container .form-control {
  width: 100%;
  padding: 10px 40px 10px 40px; /* Adjust padding to accommodate icons */
  border-radius: 40px;
  border: 1px solid #ccc;
  padding-left: 35px; /* Add padding to the left to prevent overlap */
}

.search-icon {
  position: absolute;
  left: 10px; /* Position the icon */
  color: #888;
  font-size: 18px;
}

.clear-icon {
  position: absolute;
  right: 10px;
  color: #888;
  font-size: 18px;
  cursor: pointer;
}
.payment-options-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Adjust gap between buttons as needed */
  margin-top: 20px; /* Adjust top margin as needed */
}

.payment-button {
  width: 50%; /* Adjust width as needed */
  padding: 10px 20px; /* Adjust padding as needed */
  border-radius: 5px; /* Adjust border radius as needed */
  font-size: 16px; /* Adjust font size as needed */
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.payment-button.primary {
  color: white; /* Primary button text color */
  border: none;
}

.payment-button.outline-primary {
  background-color: transparent;
  color: black;
}

.payment-button.primary:hover,
.payment-button.outline-primary:hover {
  opacity: 0.8; /* Hover effect for buttons */
}



.main-banner-new {
  width: 100%;
  height: 75vh;
}

/* Adjust height for smaller screens */
@media screen and (max-width: 1200px) {
  .main-banner-new {
    height: 60vh; /* Adjust as needed */
  }
}

@media screen and (max-width: 992px) {
  .main-banner-new {
    height: 50vh; /* Adjust as needed */
  }
}

@media screen and (max-width: 768px) {
  .main-banner-new {
    height: 40vh; /* Adjust as needed */
  }
}

@media screen and (max-width: 576px) {
  .main-banner-new {
    height: 30vh; /* Minimum height */
  }
}

.disableheader {
  display: none;
}



.receipt-container {
  max-width: 600px;
  margin: 0 10px;
  border: 1px solid #000;
  padding: 20px;
  font-family: monospace;
  background: #fff;
  border-radius: 20px;
}

.receipt-header, .receipt-footer {
  text-align: center;
  border-bottom: 1px dashed #000;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.receipt-header h1, .receipt-footer p {
  margin: 0;
  padding: 0;
}

.receipt-body {
  max-height: 400px;
  overflow-y: auto;
}

.receipt-item {
  border-bottom: 1px dashed #000;
  padding: 10px 0;
}

.receipt-item:last-child {
  border-bottom: none;
}

.receipt-item h5 {
  margin: 0 0 10px 0;
}

.receipt-details p {
  margin: 5px 0;
}

.text-danger {
  color: red;
}




.contact-section {
  height: 80vh;
  background-color: #eeeeee;
}

.contact-section .title {
  font-size: 2.5rem;
  font-weight: bold;
}

.contact-section .contact-info {
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.contact-section .text-orange {
  color: #d9534f;
  font-weight: bold;
  font-size: 1rem;
}






.testimonial-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.3s;
}

.testimonial-card:hover {
  transform: scale(1.05);
}

.testimonial-card .card-body {
  padding: 20px;
}

.testimonial-card .testimonial-info {
  margin-top: 15px;
}

.testimonial-card .testimonial-name {
  font-size: 18px;
  font-weight: bold;
}

.testimonial-card .testimonial-position {
  font-size: 14px;
  color: #777;
}

.testimonial-card .quote {
  font-size: 24px;
  color: #777;
}

.petal-test-back{ 
  background-color: #eeeeee;

}




.content-inner {
  padding: 50px 0;
}

.petal-back { 
  background-color: #eeeeee;

}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 15px;
}

.image-grid img {
  width: 100%;
  height: 250px;
  border-radius: 8px;
  object-fit: cover;
}

.text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}



.text-content p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.text-content .btn {
  align-self: start;
}

@media (max-width: 768px) {
  .image-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
  }

  .text-content .title {
    font-size: 28px;
  }

  .text-content p {
    font-size: 14px;
  }

  .text-content .btn {
    width: 100%;
    text-align: center;
  }
}





.main-bnr-three {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.top-banner-text {
  position: absolute;
  top: 20px; 
  width: 100%;
  z-index: 10;
}

.top-banner-text .sub-title {
  font-size: 24px;
  color: #000;
}

.center-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.center-content .bnr-text {
  font-size: 48px;
  color: #000;
  margin-bottom: 20px; /* Adjust this value as needed for spacing */
}

.banner-btn .btn {
  margin: 0 10px;
  padding: 10px 20px;
  cursor: pointer;
}



.social-icons {
  position: absolute;
  bottom: 20px; /* Adjust this value as needed for vertical spacing from the bottom */
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10;
  gap: 60px
}



.petals-text{ 
  font-size: 30px;
  font-weight: 600;
  font-family: Georgia, 'Times New Roman', Times, serif;


}







.category-menu-wrapper {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  padding: 10px 0;
  overflow-x: auto;
  white-space: nowrap;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;

}

.category-menu {
  display: flex;
  align-items: center;
}

.newshadow { 
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.category-item {
  margin-right: 15px;
}

.category-item.active {
  /* background-color: #ee2737; */
  border: 1px solid #ee2737;

}

.container-fluid {
  padding-top: 20px; /* Adjust based on the height of your sticky category menu */
}

.waitlist-page-container {
  border: 2px solid #e9ecef;
  border-radius: 20px;
  padding: 20px;
}


.swal2-custom-z-index {
  z-index: 9999999; /* Adjust this value as needed to be higher than your modal's z-index */
}




















.hover-zoom {
  overflow: hidden;
}

.hover-zoom img {
  transition: all 0.3s ease 0s;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .addMargin {
    margin-top: 50px;
  }
}

.date-row {
  position: relative;
  overflow: hidden;
  transition: 1s ease-in-out;
}



.scroll-view {
  display: flex;
  overflow-x: auto;
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  -ms-overflow-style: none; /* Hide scrollbar for IE/Edge */
}

.scroll-view::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome/Safari/Opera */
}

.date-box {
  cursor: pointer;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px; /* Set a minimum width for each date box */
  text-align: center;
  position: relative;
  border-radius: 15px;
  max-height: 100px;
  width: 150px;
}

.day {
  /* Styling for the weekday */
  font-weight: bold;
  width: 60px;
}

.date {
  /* Styling for the date */
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.scroll-view::before,
.scroll-view::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px; /* Set the width of the scroll viewer */
  z-index: 1;
}

.scroll-view::before {
  left: 0;
  background: linear-gradient(to right, transparent, white); /* Gradient to create fade effect */
}

.scroll-view::after {
  right: 0;
  background: linear-gradient(to left, transparent, white); /* Gradient to create fade effect */
}

.filters {
  background-color: #f8f9fa; /* Change to desired background color */
  border-radius: 5px;
  position: sticky;
  top: 80px; /* Adjust the distance from the top of the screen */
}

.col-md-2 {
  flex: 0 0 auto; /* Ensure the column does not grow or shrink */
  max-width: 200px; /* Adjust the maximum width of the column */
}

.filter-item {
  margin-bottom: 5px; /* Adjust vertical spacing between filter items */
}

.filter-item.active {
  color: #f3f737;
}

.filter-link {
  display: block;
  padding: 10px;
  border-radius: 5px;
  color: black; /* Change to desired text color */
  text-decoration: none;
  transition: background-color 0.3s ease;
  font-weight: 650;
  
}

.filter-link:hover {
  background-color: #f4da64; /* Change to desired active filter background color */


}


.hover-zoom img:hover {
  transform: scale(1.25);
}

.checkout-card-body{ 
  scale: 1;
}

.checkout-card-body:hover {
  transform: scale(1.01);
  transition: all 0.3s ease 0s;
  cursor: pointer;
}

.fa-trash-alt{ 
  color: rgb(0, 0, 0);
  font-size: 16px;
}

.fa-trash-alt:hover {
  color: rgb(65, 128, 70);  
}


.checkbox-wrapper-47 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}




.checkbox-wrapper-47 label {
  position: relative;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: black;
  font-weight: 500;
  margin: 5px;
}

.checkbox-wrapper-47 label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid #928c8c;
  border-radius: 6px;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label {
  padding-left: 1em;
  color: #0f5229;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label:before {
  top: 0;
  width: 100%;
  height: 2em;
  border-color: #2cbc63;
}

.checkbox-wrapper-47 label,
.checkbox-wrapper-47 label::before {
  transition: 0.25s all ease;
}

.quantity-input {
  display: flex;
  align-items: center;
}

.quantity-input button {
  background-color: #f8f9fa;
  border: none;
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.quantity-input button:hover {
  background-color: #e9ecef;
}

.quantity-input input[type="number"] {
  width: 50px;
  text-align: center;
}
/* CSS to style the scrollbar */
/* Works on Chrome, Edge, and Safari */

/* The container with the scrollbar */
.overflow-auto::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Handle on the scrollbar */
.overflow-auto::-webkit-scrollbar-thumb {
  background: #ccc; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners for the scrollbar handle */
}

/* Handle on hover */
.overflow-auto::-webkit-scrollbar-thumb:hover {
  background: #aaa; /* Color of the scrollbar handle on hover */
}

/* Track */
.overflow-auto::-webkit-scrollbar-track {
  background: #f2f2f2; /* Color of the scrollbar track */
}

/* Track on hover */
.overflow-auto::-webkit-scrollbar-track:hover {
  background: #ddd; /* Color of the scrollbar track on hover */
}

@media screen and (max-width: 768px) {
  .card .card-body .rounded-3 {
    display: none; /* Hide the image */
  }
  .card-body h5,
  .card-body p,
  .card-body .fw-normal {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }

  .card-body .fw-normal {
    font-weight: normal; /* Ensure font weight is normal */
  }
}

.custom-button {
  color: white;
  border: 1px solid black;
}

.custom-button:hover,
.custom-button:focus,
.custom-button:active {
  background-color: white;
  border: 1px solid black;
}

.custom-button.active {
  color: white !important;
}

.viewcart-action-text {
  color: #3f9cff;
}

.viewcart-action-text:hover {
  cursor: pointer;
  color: rgb(255, 102, 0);
}

.cart-item {
  display: flex;
  align-items: start;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
  transition: background-color 0.3s ease;
  scale: 1;
  border-radius: 20px;

}

.cart-item-view-cart:hover {
  transition: background-color 0.3s ease;
}
.cart-item-view-cart:last-child {
  border-bottom: none;
}

.cart-item-view-cart img.cart-item-image {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
}



/* Custom styles for Home component */

.hero-section {
  height: 100vh;
  background-size: cover;
  background-position: center;
  color: #000000;
  border-radius: 50px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.black-button { 
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.2em;
  text-decoration: none;
  transition: 0.3s;


}


.black-button.disable-button {
  background-color: #e4e4e4;
  color: #535353;
  cursor: not-allowed;

}

.black-button.enable-button {
  color: white;
  cursor: pointer;
  
}

.black-button:hover {
  background-color: #333;
  transition: 0.3s;
}


.hero-section h1 {
  font-size: 4em;
}

.custom-button {
  font-size: 1.2em;
  padding: 20px;
  border-radius: 20px;
  font-family: 'Permanent Marker', cursive;

}

.custom-button:hover {
  background-color: #ffae00;
}

.custom-image {
  max-height: 200px;
}

/* CustomModal.css */

/* Overlay */
.custom-modal-overlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end; /* Align to bottom for small screens */
  z-index: 9999;
}



.button-77 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
  font-family: 'Permanent Marker', cursive;

}

@media (min-width: 768px) {
  .button-77 {
    padding: 19px 32px;
  }
}

.button-77:before,
.button-77:after {
  border-radius: 80px;
}

.button-77:before {
  background-color: rgba(249, 58, 19, .32);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-77:after {
  background-color: initial;
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-77:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
}

.button-77:active:not(:disabled) {
  color: #ccc;
}

.button-77:active:not(:disabled):after {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-77:disabled {
  cursor: default;
  opacity: .24;
}


















.tip-options-container {
  display: flex;
  margin-bottom: 15px;
}

.tip-option {
  flex: 1;
}

.tip-option.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}


























.header-visible {
  transform: translateY(0);
  transition: transform 0.3s ease-in-out;
  z-index: 1050;
}

.header-hidden {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
  z-index: 1050;
  display: none;
}



.logo-img {
  width: 40px;
  height: 40px;
}

.cart-icon {
  font-size: 24px;
  margin-right: 5px;
}

.cart-count {
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}





/* CheckoutPage.css */

.checkout-page {
  padding: 30px;
  background-color: #f8f9fa;
  font-family: 'Arial', sans-serif;
}

.back-to-store {
  font-size: 1rem;
  color: #007bff;
  text-decoration: none;
}

.section {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pickup-details .map-image {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 8px;
}

.pickup-details .pickup-location {
  font-weight: bold;
  margin-bottom: 20px;
}

.pickup-option {
  display: block;
  width: 100%;
  padding: 10px;
  border: 2px solid #dee2e6;
  border-radius: 10px;
  text-align: center;
  background-color: #fff;
}

.payment .edit-payment {
  margin-top: 10px;
  width: 100%;
}

.cart-summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}


.cart-summary {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  cursor: pointer;

}

.cart-item {
  display: flex;
  align-items: start;
  padding: 10px 0;
  border-bottom: 1px solid #dee2e6;
  transition: background-color 0.3s ease;
  scale: 1;
  transition: scale 0.3s ease;
  border-radius: 20px;

}

.cart-item:hover {
  transition: background-color 0.3s ease;
  scale: 1.01;
  transition: scale 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item img.cart-item-image {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  object-fit: cover;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.cart-item-quantity {
  width: 30px;
  height: 30px;
  border: 1px solid #dee2e6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.item-price {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
  margin-left: 10px;
}

.item-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.item-attribute {
  font-size: 0.875rem;
  color: #666;
}

.item-instructions {
  font-size: 0.875rem;
  color: #666;
  font-style: italic;
}

.promotion {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 10px;
}

.order-total .order-summary {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.order-total .order-summary p {
  display: flex;
  justify-content: space-between;
  margin: 0;
  font-weight: bold;
}

.place-order-btn {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  background-color: #000000;
  border: none;
  transition: background-color 0.3s ease;
  font-size: larger;
  font-weight: bold;
  color: white;
}

.place-order-btn:hover {
  background-color: #505050;
  cursor: pointer;
}

.place-order-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}


.gmp-map {
  width: 100%;
  height: 280px;
  margin-bottom: 20px;
  border-radius: 30px;
  margin-top: 10px;
}

















.checkbox-wrapper-47 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}


/* AddToCartModal.css */

.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.item-image {
  width: 100%;
  max-height: 220px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.item-description {
  font-size: 1rem;
  color: #666;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
}

.custom-checkbox label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #007bff;
  border-radius: 4px;
  background: #fff;
}

.custom-checkbox input[type="checkbox"]:checked + label:before {
  background: #007bff;
  border-color: #007bff;
}

.custom-checkbox input[type="checkbox"]:checked + label:after {
  content: "";
  position: absolute;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.special-instructions {
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quantity-control {
  display: flex;
  align-items: center;
}

.quantity-btn {
  cursor: pointer;
  background-color: #000000;
  border: 1px solid #ced4da;
  color: white;
}

.quantity-input {
  width: 50px;
  text-align: center;
}

.add-to-cart-btn {
  background-color: #000000;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  color: white;
}

.add-to-cart-btn:hover {
  background-color: #ccc;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.checkbox-wrapper-47 label {
  position: relative;
  padding-left: 2em;
  padding-right: 1em;
  line-height: 2;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: black;
  font-weight: 500;
  margin: 5px;
}

.checkbox-wrapper-47 label:before {
  box-sizing: border-box;
  content: " ";
  position: absolute;
  top: 0.3em;
  left: 0;
  display: block;
  width: 1.4em;
  height: 1.4em;
  border: 2px solid #928c8c;
  border-radius: 6px;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label {
  padding-left: 1em;
  color: #0f5229;
}

.checkbox-wrapper-47 input[type=checkbox]:checked + label:before {
  top: 0;
  width: 100%;
  height: 2em;
  border-color: #2cbc63;
}

.checkbox-wrapper-47 label,
.checkbox-wrapper-47 label::before {
  transition: 0.25s all ease;
}


.page-content {
  background-color: #f8f9fa;
}

.content-inner {
  padding-bottom: 100px;
}



.category-container { 
  border-top: 2px solid #ee2737;
  border-bottom: 2px solid #ee2737;
  /* border-right: 2px solid #000000; */
  /* border-left: 2px solid #000000; */
}

.category-icon {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.category-list-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  padding: 0 10px;
  scrollbar-color: black;
  scrollbar-width: thin;
  margin: 10px 0;
}

.category-list {
  display: flex;
  align-items: center;
}

.category-item {
  display: inline-block;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #555;
  background-color: #e9ecef;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.category-item:hover {
  background-color: #d4d7da;
}

.category-item.active {
  background-color: #ee2737;
  color: white;
}

.category-modal-content {
  display: flex;
  flex-direction: column;
}

.category-modal-item {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  
}

.category-modal-item.active {
  background-color: #000000;
  color: white;
}

.category-modal-item:hover {
  background-color: #000000;
  color: white;
}

.card-container {
  margin-bottom: 30px;
}

.item-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 20px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  padding: 10px;
  min-height: 120px;
  max-height: 80px;
}

.item-card:hover {
  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
}

.item-image-container {
  flex-shrink: 0;
  margin-right: 15px;
}

.item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8px;
}


.item-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  
}

.item-title {
  font-size: .9rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.chinese-name {
  font-size: 0.875rem;
  color: #6c757d;
}

.item-price {
  font-size: 1rem;
  color: #343a40;
  margin-bottom: 5px;
}

.item-description {
  font-size: 0.8rem;
  color: #6c757d;
  flex-grow: 1;
}

.item-add-btn {
  position: absolute;
  bottom: 0px;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.item-add-btn:hover {
  background-color: #ccc;
}

.fixed-bottom {
  background-color: #ffffff;
  border-top: 1px solid #dee2e6;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
}

.newshadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.btn-primary {
  background-color: #000000;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  color: white;
}

.btn-primary:hover {
  background-color: #ccc;
}

/* Modal Styling */
.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1050;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s;
}

.custom-modal-overlay.show {
  visibility: visible;
  opacity: 1;
}

.custom-modal {
  background-color: white;
  width: 100%;
  border-radius: 10px 10px 0 0;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;
  max-height: 65vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: black;
}

.custom-modal-overlay.show .custom-modal {
  transform: translateY(0);
}


@keyframes swing {
  0%, 100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(30deg);
  }
  30% {
    transform: rotate(-25deg);
  }
  50% {
    transform: rotate(20deg);
  }
  70% {
    transform: rotate(-15deg);
  }
  90% {
    transform: rotate(10deg);
  }
}

.swing {
  animation: swing 0.5s ease-in-out; /* Apply the swing animation for 0.5 seconds */
}

.custom-footer-section { 
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ee2737;
  cursor: pointer;
}




.custom-footer .btn {
  margin: 0;
  font-size: 16px;
}

.custom-footer .badge {
  font-size: 18px;
}

.custom-badge {
  color: rgb(255, 255, 255);
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




.not-disable{
  background-color: #FF0000;
  color: white;
   padding: 10px; 
   text-align: center; 
   cursor: pointer;
}
.disable-button {
  background-color: #e4e4e4;
  color: #535353;
  cursor: not-allowed;
  padding: 10px; 
  text-align: center; 

}


.suggested-item {
  min-width: 30%;
}

.suggested-item img {
  width: 100%;
}

.suggested-item .suggested-item-name,
.suggested-item .suggested-item-price {
  margin: 0;
}

.text-primary {
  color: #007bff;
}

.suggested-item-image { 
  width: 100%;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
}
